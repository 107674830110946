import request from '@/core/services/api.services.request'

export function getAllorder(params) {
  return request({
    url: '/delivery/status',
    method: 'get',
    params: params
  })
}

export function getAllComplete(params) {
  return request({
    url: '/delivery/complete/order/list',
    method: 'get',
    params: params
  })
}

export function getAllDeliveryorder() {
  return request({
    url: '/delivery/update/driver',
    method: 'get'   
  })
}